import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  AppBar,
  Button,
  Card,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
// routes
// @types
import { IInvoice, IInvoiceAddress } from '../../../../@types/invoice';
// mock
// components
import FormProvider from '../../../../components/hook-form';
//
import InvoiceNewEditDetails from './InvoiceNewEditDetails';
import InvoiceFromTo from './InvoiceFromTo';
import InvoiceNewEditStatusDate from './InvoiceNewEditStatusDate';
import Iconify from '../../../../components/iconify';
import ReactPDF, { PDFDownloadLink } from '@react-pdf/renderer';
import CryptoInvoicePDF from '../details/CryptoInvoicePDF';
import InvoiceDetails from '../details';
import { LoadingButton } from '@mui/lab';
import InvoicePaymentDetails from './InvoicePaymentDetails';
import { isAddress } from 'ethers/lib/utils';
import ReactGA from 'react-ga4';
import { useAccount } from 'wagmi';
import pdf = ReactPDF.pdf;

// ----------------------------------------------------------------------

type IFormValuesProps = Omit<IInvoice, 'createDate' | 'dueDate' | 'invoiceFrom' | 'invoiceTo'>;

function downloadBlob(blob: Blob, fileName: string) {
  const aElement = document.createElement('a');
  aElement.setAttribute('download', fileName);
  const href = URL.createObjectURL(blob);
  aElement.href = href;
  aElement.setAttribute('target', '_blank');
  aElement.click();
  URL.revokeObjectURL(href);
}

const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    if (!blob) resolve(null);
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

interface FormValuesProps extends IFormValuesProps {
  createDate: Date | null;
  dueDate: Date | null;
  invoiceFrom: IInvoiceAddress | null;
  invoiceTo: IInvoiceAddress | null;
}

type Props = {
  isEdit?: boolean;
  currentInvoice?: FormValuesProps;
};

export default function InvoiceGeneratorForm({ isEdit, currentInvoice }: Props) {
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const { address, isConnected } = useAccount();

  const [open, setOpen] = useState(false);

  const FromSchema = Yup.object().shape({
    name: Yup.string().required('Company name or website is required'),
    email: Yup.string().email(),
    address: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string(),
    logo: Yup.mixed(),
    // Yup.mixed().test('required', 'Logo is required', (value) => value !== ''),
  });

  const ToSchema = Yup.object().shape({
    name: Yup.string().required('Company name or website is required'),
    email: Yup.string().email(),
    address: Yup.string().required('Address is required'),
    country: Yup.string().required('country is required'),
    state: Yup.string(),
  });

  const InvoiceSchema = Yup.object().shape({
    from: Yup.object().shape({
      freeText: Yup.string().required('Your Business info is required'),
      logo: Yup.mixed(),
    }),
    to: Yup.object().shape({
      freeText: Yup.string().required('Your Business info is required'),
    }),
    network: Yup.number().required(),
    walletAddress: Yup.string()
      .required('Wallet Address is required')
      .test('valid-address', 'Wallet Address is not valid', (value) =>
        value ? isAddress(value) : false
      ),
    cryptocurrency: Yup.string().required(),
    fiat: Yup.string().required(),
    dateOfIssue: Yup.string().nullable().required('Date of Issue is required'),
    dueDate: Yup.string().nullable().required('Due date is required'),
  });

  const from = {
    name: '',
    email: '',
    address: '',
    country: '',
    state: '',
    city: '',
    logo: '',
  };
  const to = {
    name: '',
    email: '',
    address: '',
    country: '',
    state: '',
    city: '',
  };

  const defaultValues = useMemo(
    () => ({
      from: { freeText: '', logo: '' },
      to: { freeText: '' },
      network: 1,
      walletAddress: '',
      cryptocurrency: '',
      fiat: 'USD',
      invoiceNumber: currentInvoice?.invoiceNumber || '',
      dateOfIssue: currentInvoice?.createDate || new Date(),
      dueDate: currentInvoice?.dueDate || null,
      taxes: currentInvoice?.taxes || 0,
      discount: currentInvoice?.discount || 0,
      items: currentInvoice?.items || [
        { title: '', description: '', quantity: 1, price: 0, total: 0 },
      ],
      totalPrice: currentInvoice?.totalPrice || 0,
    }),
    [currentInvoice]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(InvoiceSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    getValues,
    formState: { isSubmitted, errors },
  } = methods;

  useEffect(() => {
    if (isEdit && currentInvoice) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentInvoice]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGenerate = async (data: any) => {
    setLoadingGenerate(true);
    try {
      const invoice = CryptoInvoicePDF({ invoice: data as any });
      const blob = await pdf(invoice).toBlob();
      setLoadingGenerate(false);
      downloadBlob(blob, 'crypto-invoice');
      console.log('isConnected: ', isConnected ? `_${address}` : '');
      ReactGA.event('invoice_generator', {
        paid_amount: data.totalPrice,
        network: data.network,
        cryptocurrency: data.cryptocurrency,
        connected_address: isConnected ? `_${address}` : '',
      });
    } catch (error) {
      console.error(error);
      setLoadingGenerate(false);
    }
  };

  return (
    <>
      <FormProvider methods={methods}>
        <Card>
          <InvoiceFromTo />
          <Stack sx={{ py: 0, px: 3 }}>
            <Typography sx={{ mb: 3 }} color={'text.disabled'} variant={'h6'}>
              Payment Instructions:
            </Typography>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{}}>
              <InvoicePaymentDetails />
              <InvoiceNewEditStatusDate />
            </Stack>
          </Stack>
          <InvoiceNewEditDetails />
        </Card>

        <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            {Object.keys(errors).length > 0 && isSubmitted && (
              <Typography variant={'caption'} color={'error'}>
                Errors in form, correct it!
              </Typography>
            )}
            <LoadingButton
              loading={loadingGenerate}
              size="large"
              variant="contained"
              onClick={handleSubmit(handleGenerate)}
            >
              Generate Invoice
            </LoadingButton>
          </Stack>
        </Stack>
      </FormProvider>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={handleClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
            <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
              Generated Invoice
            </Typography>
            <PDFDownloadLink
              document={<CryptoInvoicePDF invoice={getValues() as any} />}
              fileName={'test'}
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <Tooltip title="Download PDF">
                  <Button color="inherit" variant="outlined">
                    {loading ? <CircularProgress size={24} color="inherit" /> : <>Download PDF</>}
                  </Button>
                </Tooltip>
              )}
            </PDFDownloadLink>
          </Toolbar>
        </AppBar>
        <InvoiceDetails invoice={getValues() as any} />
      </Dialog>
    </>
  );
}
