import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { GA_MEASUREMENT_ID } from './config';

export default function usePageTracking() {
  if (!window.location.href.includes('localhost')) {
    ReactGA.initialize(GA_MEASUREMENT_ID);
  }
  const location = useLocation();

  useEffect(() => {
    //TODO don't use this hook if local dev. implement differently
    if (!window.location.href.includes('localhost')) {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    }
  }, [location.pathname, location.search]);
}