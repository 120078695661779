// @mui
import { Container, Typography } from '@mui/material';
// routes
// components
import { useSettingsContext } from '../../components/settings';
// sections
import InvoiceGeneratorForm from '../../sections/@dashboard/invoice/form/InvoiceGeneratorForm';

// ----------------------------------------------------------------------

export default function InvoiceGeneratorPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <Typography variant="h4" gutterBottom>
        Invoice Generator
      </Typography>
      <InvoiceGeneratorForm />
    </Container>
  );
}
