// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers';
import { Stack, TextField } from '@mui/material';
// components
import { RHFTextField } from '../../../../components/hook-form';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['paid', 'unpaid', 'overdue', 'draft'];

// ----------------------------------------------------------------------

export default function InvoiceNewEditStatusDate() {
  const { control, watch } = useFormContext();
  const fieldSize = 'small';
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <RHFTextField size={fieldSize} name="invoiceNumber" label="Invoice number" />
      <Stack direction={'row'} spacing={2}>
        <Controller
          name="dateOfIssue"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              label="Date of Issue"
              value={field.value}
              onChange={(newValue) => {
                field.onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  size={fieldSize}
                  {...params}
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />

        <Controller
          name="dueDate"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              label="Due date"
              value={field.value}
              onChange={(newValue) => {
                field.onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  size={fieldSize}
                  {...params}
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        />
      </Stack>
    </Stack>
  );
}
