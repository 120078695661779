import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps, Link, Stack } from '@mui/material';
import Beta from './Beta';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    const LOGO_SECONDARY = theme.palette.text.primary;

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 131,
          height: 37,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 131 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.0234 8.32123H39.7569C46.8148 8.32123 51.191 12.3582 51.191 19.1076C51.191 26.0113 46.784 29.894 39.7569 29.894H34.0234V8.32123ZM39.9111 26.1347C44.0098 26.1347 46.6606 23.7615 46.6606 19.1076C46.6606 14.5771 44.0098 12.1114 39.9111 12.1114H38.3708V26.1347H39.9111Z"
            fill={LOGO_SECONDARY}
          />
          <path
            d="M54.7383 8.32123H59.0837V25.9804H66.1107V29.894H54.7383V8.32123Z"
            fill={LOGO_SECONDARY}
          />
          <path
            d="M71.2775 12.2348H66.2227V8.32123H80.6469V12.2348H75.6229V29.894H71.2775V12.2348Z"
            fill={LOGO_SECONDARY}
          />
          <path
            d="M84.3965 8.32123H90.3131C94.2266 8.32123 96.7541 10.6327 96.7541 14.5463C96.7541 18.429 94.1032 20.8639 90.3131 20.8639H86.8911V29.894H84.3945V8.32123H84.3965ZM90.0971 18.6449C92.6863 18.6449 94.1958 17.0429 94.1958 14.5463C94.1958 12.0806 92.6863 10.5402 90.0971 10.5402H86.8911V18.6469H90.0971V18.6449Z"
            fill={LOGO_SECONDARY}
          />
          <path
            d="M101.745 22.8981L99.1563 29.8943H96.6289L104.705 8.31964H107.232L115.308 29.8943H112.781L110.191 22.8981H101.745ZM105.967 11.063L102.515 20.7408H109.449L105.967 11.063Z"
            fill={LOGO_SECONDARY}
          />
          <path
            d="M120.953 19.7862L114.266 8.32123H117.132L122.187 17.413L127.335 8.32123H130.109L123.421 19.7862V29.8959H120.955V19.7862H120.953Z"
            fill={LOGO_SECONDARY}
          />
          <path
            d="M0.953125 8.31167H15.6685V23.027L23.0252 15.6703V4.62946C23.0252 2.59942 21.3807 0.954956 19.3507 0.954956H8.30984L0.953125 8.31167Z"
            fill={PRIMARY_MAIN}
          />
          <path
            d="M5.11873 18.8713L11.9935 11.9966V29.704C11.9935 30.1378 11.8219 30.5522 11.5154 30.8588L6.03447 36.3397C5.51973 36.8544 4.64062 36.4901 4.64062 35.7633V20.0261C4.64062 19.5943 4.8122 19.1779 5.11873 18.8713Z"
            fill={LOGO_SECONDARY}
          />
        </svg>
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
        <Stack direction={'row'} spacing={0.1} alignItems={'end'}>
          <>{logo}</>
          <Beta />
        </Stack>
      </Link>
    );
  }
);

export default Logo;
