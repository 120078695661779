import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import Image from '../../../../components/image';
import { Network } from '../../../../assets/data/networks';
import { IToken, ITokenBalance, TokenType } from '../../../../@types/network';
import {
  Address,
  erc20ABI,
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  usePrepareSendTransaction,
  useSendTransaction,
  useSwitchNetwork,
  useWaitForTransaction,
} from 'wagmi';
import { isAddress, parseUnits } from 'ethers/lib/utils';
import { useEffect, useState } from 'react';
import { fCryptocurrency, fCurrency } from '../../../../utils/formatNumber';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFTextField } from '../../../../components/hook-form';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../../components/iconify';
import { dispatch, useSelector } from '../../../../redux/store';
import {
  getTokenPrices,
  selectTokenBalancesByNetworkSelector,
} from '../../../../redux/slices/tokentPrice';
import { getNetwork, selectAllNetworkTokensSelector } from '../../../../redux/slices/network';
import ReactGA from 'react-ga4';
import TokenTitleRow from './TokenTitleRow';

interface FormValuesProps {
  amount: number;
  address: string;
}

function SendForm({
  token,
  tokenBalance,
  network,
}: {
  token: IToken;
  tokenBalance: ITokenBalance;
  network: Network;
}) {
  const SendSchema = Yup.object().shape({
    amount: Yup.number()
      .positive('Amount should be greater than 0')
      .transform((value) => (Number.isNaN(value) ? null : value))
      .max(tokenBalance.balanceUSD, 'Amount should be less than your balance')
      .nullable()
      .required('Amount required'),
    address: Yup.string()
      .required('Recipient Address is required')
      .test('valid-address', 'Recipient Address is not valid', (value) =>
        value ? isAddress(value) : false
      ),
  });

  const { address } = useAccount();
  const tokens = useSelector((state) => selectAllNetworkTokensSelector(state));

  const defaultValues = {
    amount: 0,
    address: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SendSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();

  // const [debouncedAmount] = useDebounce(values.amount, 500);
  // const [debouncedAddress] = useDebounce(values.address, 500);

  const { config, error } = usePrepareContractWrite({
    address: token.tokenAddress as Address,
    abi: erc20ABI,
    functionName: 'transfer',
    args: [
      values.address as any,
      parseUnits(values?.amount ? values.amount.toString() : '0', token.decimals),
    ],
    enabled: token.tokenType !== TokenType.native,
  });

  const prepareSendTransaction = usePrepareSendTransaction({
    request: {
      to: values.address as any,
      value: parseUnits(values?.amount ? values.amount.toString() : '0', token.decimals),
    },
    enabled: token.tokenType === TokenType.native,
  });

  const nativeTransaction = useSendTransaction(prepareSendTransaction.config);
  const { data, write } = useContractWrite(config);
  const { isLoading, isSuccess, status } = useWaitForTransaction({
    hash: token.tokenType === TokenType.native ? nativeTransaction.data?.hash : data?.hash,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(getNetwork(address as string, network.chainId, true));
      dispatch(getTokenPrices(network.chainId, tokens, true));
    }
  });

  const onSubmit = async (data: FormValuesProps) => {
    try {
      console.log('sent');
      if (token.tokenType === TokenType.native) {
        nativeTransaction.sendTransaction?.();
      } else {
        write?.();
      }
      ReactGA.event('send_token', {
        symbol: token.symbol,
        connected_address_send_token: `_${address}`,
        network: token.chainId,
        sent_amount: data.amount,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!isSuccess ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ pt: 2, pb: 1 }} spacing={2}>
            <TokensBalanceRow
              network={network}
              token={token}
              tokenBalances={tokenBalance ? [tokenBalance] : []}
            />

            <RHFTextField
              type="number"
              name="amount"
              id={'send-dialog-amount-field'}
              placeholder={'0'}
              label="Amount"
              helperText={tokenBalance ? fCurrency(values.amount * tokenBalance.priceUSD) : ''}
              InputProps={{
                startAdornment: <InputAdornment position="start">{token.symbol}</InputAdornment>,
              }}
            />
            <RHFTextField name="address" label="Recipient Address" />
          </Stack>
          <LoadingButton
            sx={{ mt: 3 }}
            fullWidth
            type="submit"
            variant="contained"
            loading={isLoading}
          >
            Send
          </LoadingButton>
        </FormProvider>
      ) : (
        <Stack direction={'row'} justifyContent={'center'} sx={{ px: 5 }}>
          <Typography>Transaction Sent</Typography>
        </Stack>
      )}
    </>
  );
}

function SendDialog({
  token,
  open,
  network,
  close,
}: {
  token: IToken;
  network: Network;
  open: boolean;
  close: Function;
}) {
  const handleClose = () => {
    close?.();
  };

  const { chain } = useNetwork();
  const tokenBalance = useSelector((state) =>
    selectTokenBalancesByNetworkSelector(state).find((a) => a.tokenAddress === token.tokenAddress)
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle width={250} sx={{ p: 2 }}>
        <span>{token.tokenType === TokenType.stablecoin ? 'Send Stablecoin' : 'Send Token'}</span>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify width={24} icon={'material-symbols:close'} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 3, pb: 4 }}>
        {chain?.id === network.chainId ? (
          <SendForm token={token} network={network} tokenBalance={tokenBalance} />
        ) : (
          <SwitchNetwork network={network} />
        )}
      </DialogContent>
    </Dialog>
  );
}

function SwitchNetwork({ network }: { network: Network }) {
  const { error, isLoading, pendingChainId, switchNetwork, isSuccess, status, reset, data } =
    useSwitchNetwork();
  const { chain } = useNetwork();

  const handleSwitchNetwork = () => {
    switchNetwork?.(network.chainId);
  };

  return (
    <Stack>
      <Typography variant={'body2'} paragraph>
        The active wallet network is different than requested one. Please switch the network to
        continue
      </Typography>
      <Stack
        sx={{ pt: 1, pb: 3, px: 3 }}
        direction={'row'}
        spacing={4}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Stack alignItems={'center'} spacing={1}>
          <Typography variant={'caption'}>Requested Network</Typography>
          <Stack direction={'row'} alignItems={'center'}>
            <Image sx={{ width: 30, height: 30, mr: 1 }} src={network.iconUri} />
            <Typography variant={'button'}>{network.title}</Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'center'}>
        <LoadingButton
          sx={{ width: '200px' }}
          onClick={handleSwitchNetwork}
          variant="contained"
          loadingIndicator={<span style={{ whiteSpace: 'nowrap' }}>Switching Network…</span>}
          loading={isLoading && pendingChainId === network.chainId}
        >
          Switch to {network.title}
        </LoadingButton>
      </Stack>
    </Stack>
  );
}

export function TokensBalanceRow({
  network,
  token,
  tokenBalances,
  small = false,
  send = false,
}: {
  network: Network;
  token: IToken;
  send?: boolean;
  tokenBalances: ITokenBalance[];
  small?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const balanceUSD = tokenBalances?.find((b) => b.tokenAddress === token.tokenAddress)?.balanceUSD;
  return (
    <>
      <Stack key={token.symbol} direction="row" justifyContent="space-between" alignItems={'start'}>
        <TokenTitleRow token={token} network={network} />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 0.5, sm: 2 }}
          alignItems={{ xs: 'end', sm: 'center' }}
        >
          <Stack direction={'column'} alignItems={'end'} sx={{ mt: 0.5 }}>
            {fCryptocurrency(token.balance) === 'NaN' ? (
              <Typography variant={'body2'} color={'text.secondary'}>
                Unknown
              </Typography>
            ) : (
              <>
                <Typography variant={'subtitle2'}>
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {fCryptocurrency(token.balance)} {token.symbol}
                  </span>
                </Typography>
                <Typography variant={small ? 'caption' : 'body2'} sx={{ color: 'text.secondary' }}>
                  {balanceUSD ? fCurrency(balanceUSD) : <span>&nbsp;</span>}
                </Typography>
              </>
            )}
          </Stack>
          {send && (
            <Button variant={'outlined'} sx={{ height: '30px' }} onClick={handleClickOpen}>
              Send
            </Button>
          )}
        </Stack>
      </Stack>
      <SendDialog open={open} close={handleClose} token={token} network={network} />
    </>
  );
}
