import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
    createTheme,
    StyledEngineProvider,
    ThemeOptions,
    ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
// components
import { useSettingsContext } from '../components/settings';
//
import palette from './palette';
import typography from './typography';
import shadows from './shadows';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';
import { WALLET_CONNECT_PROJECT_ID } from '../config';
import { ethereumClient } from '../wagmi-config';
import { Web3Modal } from '@web3modal/react';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { themeMode, themeDirection } = useSettingsContext();

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: palette(themeMode),
      typography,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: shadows(themeMode),
      customShadows: customShadows(themeMode),
    }),
    [themeDirection, themeMode]
  );

  const theme = createTheme(themeOptions);

  const themeVariables = {
    '--w3m-z-index': '1200',
    // '--w3m-accent-color': theme.palette.text.primary,
    // '--w3m-background-color': theme.palette.primary.main,
    // '--w3m-accent-fill-color': theme.palette.primary.contrastText,
  };


    theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
        <Web3Modal
          themeMode={themeMode}
          themeVariables={themeVariables}
          projectId={WALLET_CONNECT_PROJECT_ID}
          ethereumClient={ethereumClient}
        />
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
