import { useState } from 'react';
// form
import { useFormContext } from 'react-hook-form';
// @mui
import { Divider, Stack, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// _mock
// components
//
import InvoiceFromToForm from '../../user/InvoiceFromToForm';

// ----------------------------------------------------------------------

export default function InvoiceFromTo() {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const upMd = useResponsive('up', 'md');

  const values = watch();

  const { invoiceFrom, invoiceTo } = values;

  const [openFrom, setOpenFrom] = useState(false);

  const [openTo, setOpenTo] = useState(false);

  const handleOpenFrom = () => {
    setOpenFrom(true);
  };

  const handleCloseFrom = () => {
    setOpenFrom(false);
  };

  const handleOpenTo = () => {
    setOpenTo(true);
  };

  const handleCloseTo = () => {
    setOpenTo(false);
  };

  return (
    <Stack
      spacing={{ xs: 2, md: 5 }}
      direction={{ xs: 'column', md: 'row' }}
      divider={
        <Divider
          flexItem
          orientation={upMd ? 'vertical' : 'horizontal'}
          sx={{ borderStyle: 'dashed' }}
        />
      }
      sx={{ p: 3 }}
    >
      <Stack sx={{ width: 1 }}>
        <Typography variant="h6" sx={{ color: 'text.disabled' }} gutterBottom>
          From:
        </Typography>
        <InvoiceFromToForm parentFieldName={'from'} />
      </Stack>

      <Stack sx={{ width: 1 }}>
        <Typography variant="h6" sx={{ color: 'text.disabled' }} gutterBottom>
          Bill to:
        </Typography>
        <InvoiceFromToForm parentFieldName={'to'} isLogo={false} />
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

type AddressInfoProps = {
  name: string;
  address: string;
  phone: string;
};

function AddressInfo({ name, address, phone }: AddressInfoProps) {
  return (
    <>
      <Typography variant="subtitle2">{name}</Typography>
      <Typography variant="body2" sx={{ mt: 1, mb: 0.5 }}>
        {address}
      </Typography>
      <Typography variant="body2">Phone: {phone}</Typography>
    </>
  );
}
