// ----------------------------------------------------------------------

export enum TokenType {'stablecoin', 'native', 'other'}

export interface IToken {
    name: string;
    symbol: string;
    logo?: string;
    balance: number;
    chainId: number;
    decimals: number;
    tokenType: TokenType;
    tokenAddress: string;
}

export interface INetwork {
    [chainId: number]: IToken[];
}

export type INetworkState = {
    isLoading: boolean;
    error: Error | string | null;
    selectedChainId: number;
    chainIdTokens: INetwork;
};

export interface ITokenBalance {
    tokenAddress: string,
    balanceUSD: number,
    priceUSD: number,
    tokenType: TokenType,
};

export interface ITokenPrice {
    [chainId: number]: ITokenBalance[];
};

export type ITokenPriceState = {
    isLoading: boolean;
    error: Error | string | null;
    prices: ITokenPrice;
}

export interface ITokenAnalytics {
    balanceUSD: number;
    symbol: string;
    chainId: number;
    tokenAddress: string;
    stablecoin: boolean;
}

export type IAnalyticsState = {
    isLoading: boolean;
    error: Error | string | null;
    tokens: {
        [chainId: number]: ITokenAnalytics[]
    };
};



