import { createSlice } from '@reduxjs/toolkit';
// utils
// @types
//
import { dispatch, store } from '../store';
import { IAnalyticsState } from '../../@types/network';
import LiFi from '../../components/web3-context/LiFi';
import { isStablecoin } from './network';

// ----------------------------------------------------------------------

const initialState: IAnalyticsState = {
  isLoading: false,
  error: null,
  tokens: {},
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getNetworksSuccess(state, action) {
      state.isLoading = false;
      state.tokens = action.payload;
    },
    clearAnalytics(state) {
      state.isLoading = false;
      state.error = null;
      state.tokens = {};
    },
  },
});

// Actions
export const { clearAnalytics } = slice.actions;

// Reducer
export default slice.reducer;

export function getNetworks(walletAddress: string, chainIds: number[], force = false) {
  return async () => {
    const state = store.getState();
    if (!force && Object.keys(state.analytics.tokens).length > 0) return;
    dispatch(slice.actions.startLoading());
    try {
      const tokensResponse = await LiFi.getTokens({ chains: chainIds });
      const balances = await LiFi.getTokenBalancesForChains(walletAddress, tokensResponse.tokens);
      const positive = Object.entries(balances).reduce((acc, [key, value]) => {
        acc[key] = value
          .filter((token) => parseFloat(token.amount) > 0)
          .map((t) => ({
            balanceUSD: parseFloat(t.amount) * (t?.priceUSD ? parseFloat(t.priceUSD) : 0),
            symbol: t.symbol,
            chainId: t.chainId,
            tokenAddress: t.address,
            stablecoin: isStablecoin(t.symbol),
          }));
        return acc;
      }, {});
      dispatch(slice.actions.getNetworksSuccess(positive));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}