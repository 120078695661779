// @mui
import { Theme } from '@mui/material/styles';
import { Box, SxProps, TablePagination, TablePaginationProps, } from '@mui/material';
//

// ----------------------------------------------------------------------

type Props = {
    dense?: boolean;
    onChangeDense?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sx?: SxProps<Theme>;
};

export default function TablePaginationCustom({
                                                  dense,
                                                  onChangeDense,
                                                  rowsPerPageOptions = [-1],
                                                  sx,
                                                  ...other
                                              }: Props & TablePaginationProps) {
    return (
        <Box sx={{ position: 'relative', ...sx }}>
            <TablePagination rowsPerPageOptions={rowsPerPageOptions} component="div" {...other} />

            {/*{onChangeDense && (*/}
            {/*  <FormControlLabel*/}
            {/*    label="Dense"*/}
            {/*    control={<Switch checked={dense} onChange={onChangeDense} />}*/}
            {/*    sx={{*/}
            {/*      pl: 2,*/}
            {/*      py: 1.5,*/}
            {/*      top: 0,*/}
            {/*      position: {*/}
            {/*        md: 'absolute',*/}
            {/*      },*/}
            {/*    }}*/}
            {/*  />*/}
            {/*)}*/}
        </Box>
    );
}
