import { useState } from 'react';
// @mui
import { Card, Container, Stack, Typography } from '@mui/material';
// routes
// _mock_
// components
import Iconify from '../../components/iconify';
import { useSettingsContext } from '../../components/settings';
// sections
import { AccountGeneral } from '../../sections/@dashboard/user/account';
import Block from '../../components/settings/drawer/Block';
import ModeOptions from '../../components/settings/drawer/ModeOptions';
// ----------------------------------------------------------------------

export default function SettingsPage() {
  const { themeStretch } = useSettingsContext();

  const [currentTab, setCurrentTab] = useState('general');

  const TABS = [
    {
      value: 'general',
      label: 'General',
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral />,
    },
    // {
    //   value: 'wallet',
    //   label: 'Wallet',
    //   icon: <Iconify icon="heroicons:wallet-solid" />,
    //   component: <WalletSettings />,
    // },
    // {
    //   value: 'billing',
    //   label: 'Billing',
    //   icon: <Iconify icon="ic:round-receipt" />,
    //   component: (
    //     <AccountBilling
    //       cards={_userPayment}
    //       addressBook={_userAddressBook}
    //       invoices={_userInvoices}
    //     />
    //   ),
    // },
    // {
    //   value: 'change_password',
    //   label: 'Security',
    //   icon: <Iconify icon="ic:baseline-security"/>,
    //   component: <AccountChangePassword/>,
    // },
  ];

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>
        <Card>
          <Stack sx={{ p: 3.5 }}>
            <Block sx={{ width: { sm: '200px', md: '250px' } }} title="Mode">
              <ModeOptions />
            </Block>
          </Stack>
        </Card>

        {/*<Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>*/}
        {/*  {TABS.map((tab) => (*/}
        {/*    <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />*/}
        {/*  ))}*/}
        {/*</Tabs>*/}

        {/*{TABS.map(*/}
        {/*  (tab) =>*/}
        {/*    tab.value === currentTab && (*/}
        {/*      <Box key={tab.value} sx={{ mt: 5 }}>*/}
        {/*        {tab.component}*/}
        {/*      </Box>*/}
        {/*    )*/}
        {/*)}*/}
      </Container>
    </>
  );
}
