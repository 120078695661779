import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Container, Typography } from '@mui/material';
// redux
import { useDispatch } from '../../redux/store';
// routes
// components
import { useSettingsContext } from '../../components/settings';
// sections
import PaymentDetailsForm from '../../sections/@dashboard/e-commerce/PaymentDetailsForm';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

export default function PaymentDetailsViewPage() {
  const { themeStretch } = useSettingsContext();

  const dispatch = useDispatch();

  const { id } = useParams();

  const [current, setCurrent] = useState(undefined);

  useEffect(() => {
    try {
      axios.get('/data/payments.json').then((data) => {
        const found = data.data.payments.find((p) => p.id?.toLowerCase() === id);
        setCurrent(found);
      });
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" gutterBottom>
          Transaction Details
        </Typography>
        {current ? <PaymentDetailsForm current={current} /> : <></>}
      </Container>
    </>
  );
}
