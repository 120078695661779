import { useCallback } from 'react';
// form
import { useFormContext } from 'react-hook-form';
// @mui
import { Button, Grid, Stack, Typography } from '@mui/material';
// utils
// routes
// @types
import { IUserAccountGeneral } from '../../../@types/user';
// assets
// components
import { CustomFile } from '../../../components/upload';
import { RHFTextField, RHFUpload } from '../../../components/hook-form';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<IUserAccountGeneral, 'avatarUrl'> {
  avatarUrl: CustomFile | string | null;
}

type Props = {
  isEdit?: boolean;
  currentUser?: IUserAccountGeneral;
  isLogo?: boolean;
  parentFieldName?: string;
};

export default function InvoiceFromToForm({
  isEdit = false,
  currentUser,
  isLogo = true,
  parentFieldName,
}: Props) {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const values = watch();

  // useEffect(() => {
  //   if (isEdit && currentUser) {
  //     reset(defaultValues);
  //   }
  //   if (!isEdit) {
  //     reset(defaultValues);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isEdit, currentUser]);

  const handleRemoveFile = () => {
    // const filtered = values.from.logo && values.from.logo?.filter((file) => file !== inputFile);
    setValue('from.logo', '');
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('from.logo', newFile);
      }
    },
    [setValue]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <RHFTextField
          name={`${parentFieldName}.freeText`}
          fullWidth
          multiline
          rows={5}
          placeholder={isLogo ? 'Your Business Name and Address' : "Client's Name and Address"}
        />
        {/*<Box rowGap={3} columnGap={2} display="grid">*/}
        {/*  <RHFTextField*/}
        {/*    sx={{ gridColumn: '1 / 3' }}*/}
        {/*    size="small"*/}
        {/*    name={`${parentFieldName}.name`}*/}
        {/*    label="Company Name"*/}
        {/*  />*/}
        {/*  <RHFTextField*/}
        {/*    sx={{ gridColumn: '1 / 3' }}*/}
        {/*    size="small"*/}
        {/*    name={`${parentFieldName}.address`}*/}
        {/*    label="Address"*/}
        {/*  />*/}
        {/*  <RHFSelect*/}
        {/*    sx={{ gridColumn: '1 / 2' }}*/}
        {/*    size="small"*/}
        {/*    name={`${parentFieldName}.country`}*/}
        {/*    label="Country"*/}
        {/*    placeholder="Country"*/}
        {/*  >*/}
        {/*    <option value="" />*/}
        {/*    {countries.map((option) => (*/}
        {/*      <option key={option.code} value={option.label}>*/}
        {/*        {option.label}*/}
        {/*      </option>*/}
        {/*    ))}*/}
        {/*  </RHFSelect>*/}
        {/*  <RHFTextField*/}
        {/*    sx={{ gridColumn: '2 / 2' }}*/}
        {/*    size="small"*/}
        {/*    name={`${parentFieldName}.state`}*/}
        {/*    label="State/Region"*/}
        {/*  />*/}
        {/*  <RHFTextField*/}
        {/*    sx={{ gridColumn: '1 / 3' }}*/}
        {/*    size="small"*/}
        {/*    name={`${parentFieldName}.email`}*/}
        {/*    label="Email Address"*/}
        {/*  />*/}
        {/*</Box>*/}
      </Grid>
      {isLogo && (
        <Grid item xs={12}>
          <Stack spacing={1} alignItems={'end'}>
            <RHFUpload
              thumbnail
              accept={{ 'image/png': ['.png'], 'image/jpeg': ['.jpg', '.jpeg'] }}
              name={`${parentFieldName}.logo`}
              onDrop={handleDrop}
              onUpload={() => console.log('ON UPLOAD')}
            />
            <Stack
              width={1}
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography sx={{ paddingX: 1 }} variant={'caption'} color={'text.secondary'}>
                Allowed *.png, *.jpeg, *.jpg
              </Typography>
              <Button onClick={handleRemoveFile}>Remove</Button>
            </Stack>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
}
