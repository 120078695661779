// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeSettings({ children }: Props) {
  return (
    <>
      {/*<ThemeColorPresets>*/}
      {/*<ThemeContrast>*/}
      {/*<ThemeRtlLayout>*/}
      {children}
      {/*<SettingsDrawer />*/}
      {/*</ThemeRtlLayout>*/}
      {/*</ThemeContrast>*/}
      {/*</ThemeColorPresets>*/}
    </>
  );
}
