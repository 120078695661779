// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
import Image from '../../../../components/image';
import { Network, WALLET_NETWORKS } from '../../../../assets/data/networks';
import { useEffect, useState } from 'react';
import Iconify from '../../../../components/iconify';
import MenuPopover from '../../../../components/menu-popover';
import { dispatch, useSelector } from '../../../../redux/store';
import {
  getNetwork,
  selectAllNetworkTokensSelector,
  selectChainId,
  selectNetworkSelector,
  selectNetworkTokensSelector,
} from '../../../../redux/slices/network';
import TokenTitleRow from './TokenTitleRow';
import {
  getTokenPrices,
  selectBalancesByNetworkSelector,
  selectTokenBalancesByNetworkSelector,
} from '../../../../redux/slices/tokentPrice';
import { TokensBalanceRow } from './TokensBalanceRow';
import { useAccount } from 'wagmi';

export function CenterText({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      component={'div'}
      variant={'caption'}
      color={'text.secondary'}
      padding={2}
      align={'center'}
    >
      {children}
    </Typography>
  );
}


export default function NetworkBalances() {
    const { address, isConnected } = useAccount();

    const isLoadingNetworks = useSelector(state => state.network.isLoading);
    const tokens = useSelector(state => selectAllNetworkTokensSelector(state));
    const { stablecoins, others } = useSelector(state => selectNetworkTokensSelector(state));
    const { stablecoinsBalance, othersBalance } = useSelector(state => selectBalancesByNetworkSelector(state));
    const selectedNetwork: Network = useSelector(state => selectNetworkSelector(state));
    const tokenBalances = useSelector(state => selectTokenBalancesByNetworkSelector(state));
    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const totalBalance = stablecoinsBalance + othersBalance;

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleChangeChain = (chain: Network) => {
        dispatch(selectChainId(chain.chainId));
        handleClosePopover();
    };

    useEffect(() => {
        if (!isConnected) return;
        dispatch(getNetwork(address as string, selectedNetwork.chainId));
    }, [isConnected, address, selectedNetwork.chainId]);

    useEffect(() => {
        dispatch(getTokenPrices(selectedNetwork.chainId, tokens));
    });

    return (
      <Card>
        <CardContent>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant="subtitle2" color={'text.primary'}>
              Network Balance
            </Typography>
            <Button
              color={'inherit'}
              variant={'text'}
              endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
              onClick={handleOpenPopover}
              sx={{
                whiteSpace: 'nowrap',
                ...(openPopover && {
                  bgcolor: 'action.selected',
                }),
              }}
            >
              <Image sx={{ width: 24, height: 24, mr: 1 }} src={selectedNetwork.iconUri} />
              <span>{selectedNetwork.title}</span>
              {/*{currentNetwork?.chainId === CHAIN_ID.POLYGON*/}
              {/*    ? <Box*/}
              {/*        sx={{*/}
              {/*            display: 'flex',*/}
              {/*            gap: '0.5em',*/}
              {/*            alignItems: 'center'*/}
              {/*        }}><span>{currentNetwork?.name}</span><Typography*/}
              {/*        variant={'caption'}>(Primary)</Typography></Box>*/}
              {/*    : <span>{currentNetwork?.name}</span>*/}
              {/*}*/}
            </Button>
            <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 180 }}>
              <Stack spacing={0.75}>
                {WALLET_NETWORKS.map((option, index) => (
                  <MenuItem
                    key={index}
                    selected={option.chainId === selectedNetwork.chainId}
                    onClick={() => handleChangeChain(option)}
                  >
                    <Image disabledEffect src={option.iconUri} sx={{ width: 24, mr: 1 }} />
                    <span>{option.title}</span>
                    {/*{option.chainId === CHAIN_ID.POLYGON*/}
                    {/*    ? <Box*/}
                    {/*        sx={{*/}
                    {/*            display: 'flex',*/}
                    {/*            gap: '0.5em',*/}
                    {/*            alignItems: 'center'*/}
                    {/*        }}><span>{option.name}</span><Typography*/}
                    {/*        variant={'caption'}>(Primary)</Typography></Box>*/}
                    {/*    : <span>{option.name}</span>*/}
                    {/*}*/}
                  </MenuItem>
                ))}
              </Stack>
            </MenuPopover>
          </Stack>
          <Typography variant={'h3'} paragraph>
            {fCurrency(totalBalance)}
          </Typography>
          <Divider />
          {isLoadingNetworks ? (
            <Box component={'div'} sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
              <CircularProgress color={'inherit'} />
            </Box>
          ) : (
            <Box paddingTop={2}>
              {isConnected ? (
                <Stack spacing={2}>
                  <Stack>
                    <Typography variant="overline" color={'text.secondary'} gutterBottom>
                      Stablecoins
                    </Typography>
                    {stablecoins && stablecoins.length > 0 ? (
                      <Stack sx={{ pl: { sm: 1 } }} spacing={{ xs: 1.5, sm: 1 }}>
                        {stablecoins?.map((token, index) => (
                          <TokensBalanceRow
                            key={index}
                            send={true}
                            network={selectedNetwork}
                            tokenBalances={tokenBalances}
                            token={token}
                          />
                        ))}
                      </Stack>
                    ) : (
                      <CenterText>No Stablecoins</CenterText>
                    )}
                  </Stack>
                  <Stack>
                    <Typography variant="overline" color={'text.secondary'} gutterBottom>
                      Tokens
                    </Typography>
                    {others && others.length > 0 ? (
                      <Stack sx={{ pl: { sm: 1 } }} spacing={{ xs: 1.5, sm: 1 }}>
                        {others?.map((token, index) => (
                          <TokensBalanceRow
                            key={index}
                            send={true}
                            network={selectedNetwork}
                            tokenBalances={tokenBalances}
                            token={token}
                          />
                        ))}
                      </Stack>
                    ) : (
                      <CenterText>No Tokens</CenterText>
                    )}
                  </Stack>
                  <Stack>
                    <Typography variant="overline" color={'text.secondary'} gutterBottom>
                      Platform Token
                    </Typography>
                    <Stack sx={{ pl: { sm: 1 } }} spacing={2.5}>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <TokenTitleRow
                          token={{ logo: '/assets/icons/coins/dlt.webp', name: 'DLTPAY' }}
                          network={selectedNetwork}
                        />
                        <Typography variant="body2" color={'text.disabled'}>
                          Coming Soon
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ) : (
                <CenterText>No Wallet Connected</CenterText>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    );
}
