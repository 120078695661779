import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance from '../../utils/axios';
import axios from 'axios';
// @types
//
import { dispatch } from '../store';
import { ICoinState } from '../../@types/product';
import { toThreeDecimalPlaces } from '../../utils/number';

// ----------------------------------------------------------------------

const initialState: ICoinState = {
  isLoading: false,
  error: null,
  coins: [],
};

const APE_CONTRACT_ADDRESS = '0x4d224452801aced8b2f0aebe155379bb5d594381';
const OPTIMISM_CONTRACT_ADDRESS = '0x4200000000000000000000000000000000000042';

function mapContractAddressToSymbol(address: string) {
  switch (address) {
    case APE_CONTRACT_ADDRESS:
      return 'APE';
    case OPTIMISM_CONTRACT_ADDRESS:
      return 'OP';
  }
}

const slice = createSlice({
  name: 'coin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCoins(state, action) {
      state.isLoading = false;
      state.coins = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCoinPrices() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const requestCoinCap = `https://api.coincap.io/v2/assets?ids=${[
        'bitcoin',
        'ethereum',
        'polygon',
        'binance-coin',
        'dogecoin',
        'polkadot',
      ].join()}`;
      const response = await axios.all([
        axiosInstance.get(requestCoinCap),
        axiosInstance.post('https://api.coinbrain.com/public/coin-info', {
          // ApeCoin Price from mainnet
          '1': [APE_CONTRACT_ADDRESS],
          //Optimism from optimism
          '10': [OPTIMISM_CONTRACT_ADDRESS],
        }),
      ]);
      const coincap = response[0].data.data.map((c) => ({
        symbol: c.symbol,
        priceUsd: toThreeDecimalPlaces(c.priceUsd),
      }));
      const coinbrain = response[1].data.map((c) => ({
        symbol: mapContractAddressToSymbol(c.address),
        priceUsd: toThreeDecimalPlaces(c.priceUsd),
      }));
      dispatch(slice.actions.getCoins([...coincap, ...coinbrain]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
