import { useEffect } from 'react';
import { paramCase } from 'change-case';
import { useParams } from 'react-router-dom';
// @mui
import { Container, Typography } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getCheckouts } from '../../redux/slices/checkout';
// routes
// components
import { useSettingsContext } from '../../components/settings';
// sections
import CheckoutNewEditForm from '../../sections/@dashboard/e-commerce/CheckoutNewEditForm';

// ----------------------------------------------------------------------

export default function CheckoutDetailsViewPage() {
  const { themeStretch } = useSettingsContext();

  const dispatch = useDispatch();

  const { id } = useParams();

  const currentProduct = useSelector((state) =>
    state.product.products.find((product) => paramCase(product.id) === id)
  );

  useEffect(() => {
    dispatch(getCheckouts());
  }, [dispatch]);

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant="h4" gutterBottom>
          Checkout Details
        </Typography>
        {currentProduct ? <CheckoutNewEditForm readonly currentProduct={currentProduct} /> : <></>}
      </Container>
    </>
  );
}
