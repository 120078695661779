// @mui
import { Button, Link, Stack, Typography } from '@mui/material';
// hooks
// locales
// routes

// ----------------------------------------------------------------------

export default function Feedback() {
  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        pb: 2,
        mt: 10,
        width: 1,
        display: 'block',
        textAlign: 'center',
      }}
    >
      <div>
        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          Got a feature request? <br /> Let us know and we'll make it happen.
        </Typography>
      </div>

      <Button
        variant="contained"
        component={Link}
        href={'https://forms.gle/gKc6bHqKujTn5dYs9'}
        color={'inherit'}
        target="_blank"
        rel="noopener"
        sx={{ textTransform: 'none' }}
      >
        Request a Feature
      </Button>
    </Stack>
  );
}
