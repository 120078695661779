import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

const Beta = forwardRef<HTMLDivElement, any>(() => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const LOGO_SECONDARY = theme.palette.text.primary;

  return (
    <Typography
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        fontSize: '0.6rem',
        paddingY: 0.1,
        paddingX: 0.8,
      }}
      variant={'overline'}
      color={theme.palette.primary.contrastText}
    >
      Beta
    </Typography>
  );
});

export default Beta;
