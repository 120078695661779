// @mui
import { Container, Stack } from '@mui/material';
// auth
// _mock_
// components
import { useSettingsContext } from '../../components/settings';
// sections
import { NetworkBalances } from '../../sections/@dashboard/general/e-commerce';
// assets

// ----------------------------------------------------------------------

export default function DashboardPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Stack direction={'column'} gap={2}>
          <NetworkBalances />
        </Stack>
      </Container>
    </>
  );
}
