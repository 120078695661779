// @mui
import { Box, Card, CircularProgress, Container, Stack, Typography } from '@mui/material';
// redux
// routes
// @types
// components
import { useSettingsContext } from '../../components/settings';
import { styled, useTheme } from '@mui/material/styles';
import { dispatch, useSelector } from '../../redux/store';
import { CHAIN_ID, NETWORKS } from '../../assets/data/networks';
import { useEffect } from 'react';
import { useAccount } from 'wagmi';
import { getNetworks } from '../../redux/slices/analytics';
import Chart, { useChart } from '../../components/chart';
import { CenterText } from '../../sections/@dashboard/general/e-commerce/NetworkBalances';
import ReactGA from 'react-ga4';
// sections

// ----------------------------------------------------------------------

const CHART_HEIGHT = 400;

const LEGEND_HEIGHT = 100;

const StyledChart = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT,
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

export default function AnalyticsPage() {
  const theme = useTheme();
  const { themeStretch } = useSettingsContext();
  const { address, isConnected } = useAccount();
  const isLoading = useSelector((state) => state.analytics.isLoading);

  const balances = useSelector((state) => {
    const all = Object.entries(state.analytics.tokens).map(([chainId, tokens]) => {
      const networkLabel = NETWORKS.find((n) => chainId === n.chainId.toString())?.title;
      const stablecoins = tokens
        .filter((t) => t.stablecoin && t.balanceUSD > 0)
        .map((m) => ({
          label: `${m.symbol} (${networkLabel})`,
          value: m.balanceUSD,
        }));
      const othersBalance = tokens
        .filter((t) => !t.stablecoin && t.balanceUSD > 0)
        .reduce((acc, cur) => acc + cur.balanceUSD, 0);
      if (othersBalance === 0) {
        return stablecoins;
      }
      return [...stablecoins, { label: `Tokens (${networkLabel})`, value: othersBalance }];
    });
    return all.flat();
  });

  const chartSeries = balances.map((i) => i.value);

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.info.main,
      theme.palette.error.main,
      theme.palette.warning.main,
    ],
    labels: balances.map((i) => i.label),
    stroke: {
      colors: [theme.palette.background.paper],
    },
    legend: {
      floating: true,
      horizontalAlign: 'center',
    },
    dataLabels: {
      enabled: true,
      dropShadow: { enabled: false },
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value: number) => '',
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  useEffect(() => {
    if (isConnected) {
      ReactGA.event('load_analytics', {
        connected_address_load_analytics: `_${address}`,
      });
    }
  }, [isConnected]);

  useEffect(() => {
    dispatch(
      getNetworks(address as string, [
        CHAIN_ID.ETHEREUM,
        CHAIN_ID.POLYGON,
        CHAIN_ID.BNB_CHAIN,
        CHAIN_ID.ARBITRUM,
        CHAIN_ID.OPTIMISM,
      ])
    );
  }, [address]);

  return (
    <Container maxWidth={themeStretch ? false : 'lg'}>
      <Typography variant="h4" gutterBottom>
        Analytics
      </Typography>

      <Card>
        <Stack direction={'row'} justifyContent={'center'} sx={{ pt: 4 }}>
          <Typography variant="subtitle1" gutterBottom>
            Total Balance
          </Typography>
        </Stack>
        {isConnected ? (
          <>
            {isLoading ? (
              <Box
                component={'div'}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 3 }}
              >
                <CircularProgress color={'inherit'} />
                <Typography variant={'caption'} sx={{ px: 2 }}>
                  Exploring multi-chain data
                </Typography>
              </Box>
            ) : (
              <StyledChart dir="ltr">
                <Chart type="pie" series={chartSeries} options={chartOptions} height={280} />
              </StyledChart>
            )}
          </>
        ) : (
          <CenterText>No Wallet Connected</CenterText>
        )}
      </Card>
    </Container>
  );
}
