import { Navigate, useRoutes } from 'react-router-dom';
// auth
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import {
  CheckoutDetailsEditPage,
  EcommerceProductDetailsPage,
  Page403,
  Page404,
  Page500,
} from './elements';
import DashboardPage from '../pages/dashboard/DashboardPage';
import SettingsPage from '../pages/dashboard/SettingsPage';
import SwapPage from '../pages/dashboard/SwapPage';
import AnalyticsPage from '../pages/dashboard/AnalyticsPage';
import InvoiceGeneratorPage from '../pages/dashboard/InvoiceGeneratorPage';
import CheckoutListPage from '../pages/dashboard/CheckoutListPage';
import CheckoutDetailsViewPage from '../pages/dashboard/CheckoutDetailsViewPage';
import PaymentListPage from '../pages/dashboard/PaymentsListPage';
import PaymentDetailsViewPage from '../pages/dashboard/PaymentDetailsViewPage';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    // {
    //   path: '/',
    //   children: [{element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true}],
    // },
    // {
    //     path: 'auth',
    //     children: [
    //         {
    //             path: 'login',
    //             element: (
    //                 <GuestGuard>
    //                     <LoginPage/>
    //                 </GuestGuard>
    //             ),
    //         },
    //         {
    //             path: 'register',
    //             element: (
    //                 <GuestGuard>
    //                     <RegisterPage/>
    //                 </GuestGuard>
    //             ),
    //         },
    //         {path: 'login-unprotected', element: <LoginPage/>},
    //         {path: 'register-unprotected', element: <RegisterPage/>},
    //         {
    //             element: <CompactLayout/>,
    //             children: [
    //                 {path: 'reset-password', element: <ResetPasswordPage/>},
    //                 {path: 'new-password', element: <NewPasswordPage/>},
    //                 {path: 'verify', element: <VerifyCodePage/>},
    //             ],
    //         },
    //     ],
    // },

    // Dashboard
    {
      path: '/',
      element: (
        //TODO if use authentification uncomment
        // <AuthGuard>

        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'products',
          children: [
            { element: <CheckoutListPage />, index: true },
            { path: ':id', element: <CheckoutDetailsViewPage /> },
            { path: ':id/edit', element: <CheckoutDetailsEditPage /> },
          ],
        },
        {
          path: 'transactions',
          children: [
            { element: <PaymentListPage />, index: true },
            { path: ':id', element: <PaymentDetailsViewPage /> },
          ],
        },
        {
          path: 'dashboard',
          children: [{ element: <DashboardPage />, index: true }],
        },
        {
          path: 'crypto-invoice-generator',
          children: [{ element: <InvoiceGeneratorPage />, index: true }],
        },
        // {
        //   path: 'plugins',
        //   children: [{ element: <PluginsPage />, index: true }],
        // },
        {
          path: 'settings',
          children: [{ element: <SettingsPage />, index: true }],
        },
        // {
        //   path: 'invoices',
        //   children: [{element: <InvoicesListPage/>, index: true}],
        // },
        // {
        //   path: 'stake',
        //   children: [{ element: <StakingPage />, index: true }],
        // },
        {
          path: 'analytics',
          children: [{ element: <AnalyticsPage />, index: true }],
        },
        {
          path: 'swap',
          children: [{ element: <SwapPage />, index: true }],
        },
        // {path: 'analytics', element: <GeneralAnalyticsPage/>},
        // {path: 'banking', element: <GeneralBankingPage/>},
        // {path: 'booking', element: <GeneralBookingPage/>},
        //USER ----
        // {
        //     path: 'user',
        //     children: [
        //         {element: <Navigate to="/dashboard/user/profile" replace/>, index: true},
        //         {path: 'profile', element: <UserProfilePage/>},
        //         {path: 'cards', element: <UserCardsPage/>},
        //         {path: 'list', element: <UserListPage/>},
        //         {path: 'new', element: <UserCreatePage/>},
        //         {path: ':name/edit', element: <UserEditPage/>},
        //         {path: 'account', element: <UserAccountPage/>},
        //     ],
        // },
        // {path: 'blank', element: <BlankPage/>},
      ],
    },
    {
      path: 'checkout-page/:id',
      element: <EcommerceProductDetailsPage />,
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
