import { Box } from '@mui/material';
import ConnectButton from '../../../../components/web3-context/ConnectButton';

// ----------------------------------------------------------------------

export default function Wallet() {
  return (
    <Box px={0.5} pl={0.5}>
      <ConnectButton />
    </Box>
  );
}
