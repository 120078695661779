// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
//
import { MultiFilePreview, Upload, UploadAvatar, UploadBox, UploadProps } from '../upload';

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
    name: string;
    multiple?: boolean;
    readonly?: boolean;
}

// ----------------------------------------------------------------------

export function RHFUploadAvatar({ name, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                const isError = !!error && !field.value;

                return (
                    <div>
                        <UploadAvatar
                            accept={{
                                'image/*': [],
                            }}
                            error={isError}
                            file={field.value}
                            {...other}
                        />

                        {isError && (
                            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                                {error.message}
                            </FormHelperText>
                        )}
                    </div>
                );
            }}
        />
    );
}

// ----------------------------------------------------------------------

export function RHFUploadBox({ name, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                const isError = !!error && !field.value?.length;

                return <UploadBox error={isError} files={field.value} {...other} />;
            }}
        />
    );
}

// ----------------------------------------------------------------------

export function RHFUpload({ name, readonly, multiple, ...other }: Props) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                const isErrorWithSingle = !!error && !field.value;

                const isErrorWithMultiple = !!error && !field.value?.length;

                if (readonly) {
                    return <MultiFilePreview files={field.value} thumbnail={true}/>
                }

                return multiple ? (
                    <Upload
                        multiple
                        accept={{ 'image/*': [] }}
                        files={field.value}
                        error={isErrorWithMultiple}
                        helperText={
                            isErrorWithMultiple && (
                                <FormHelperText error sx={{ px: 2 }}>
                                    {error?.message}
                                </FormHelperText>
                            )
                        }
                        {...other}
                    />
                ) : (
                    <Upload
                        accept={{ 'image/*': [] }}
                        file={field.value}
                        error={isErrorWithSingle}
                        helperText={
                            isErrorWithSingle && (
                                <FormHelperText error sx={{ px: 2 }}>
                                    {error?.message}
                                </FormHelperText>
                            )
                        }
                        {...other}
                    />
                );
            }}
        />
    );
}
