/* eslint-disable jsx-a11y/alt-text */
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fCurrency, replaceCurrencySymbol } from '../../../../utils/formatNumber';
// @types
import { IInvoice } from '../../../../@types/invoice';
//
import styles from './InvoiceStyle';
import { WALLET_NETWORKS } from '../../../../assets/data/networks';

// ----------------------------------------------------------------------

type Props = {
  invoice: IInvoice;
};

const networkIconUri = (chainId: number) => {
  if (chainId === 1) {
    return '/assets/icons/chains/ethereum.png';
  }
};

export default function CryptoInvoicePDF({ invoice }: any) {
  const {
    items,
    taxes,
    dueDate,
    discount,
    dateOfIssue,
    totalPrice,
    from,
    to,
    network,
    walletAddress,
    cryptocurrency,
    fiat,
    invoiceNumber,
    subTotalPrice,
  } = invoice;

  const networkObj = WALLET_NETWORKS.find((w) => w.chainId === network);
  const currencySymbol = fiat === 'USD' ? '$' : '€';

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View key={'v1'} style={[styles.gridContainer, styles.mb40]}>
          {from?.logo && <Image source={from.logo.preview} style={{ height: 32 }} />}
          <View key={'v2'} style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={{ fontSize: 11, textTransform: 'uppercase', fontWeight: 700 }}>
              Invoice
            </Text>
            <Text>{invoiceNumber}</Text>
          </View>
        </View>

        <View key={'v3'} style={[styles.gridContainer, styles.mb40]}>
          <View key={'v4'} style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>From</Text>
            <Text style={styles.body1}>{from.freeText}</Text>
            {/*<Text style={styles.body1}>{invoiceFrom.address}</Text>*/}
            {/*<Text style={styles.body1}>{invoiceFrom.phone}</Text>*/}
          </View>

          <View key={'v5'} style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Bill to</Text>
            <Text style={styles.body1}>{to.freeText}</Text>
            {/*<Text style={styles.body1}>{invoiceTo.address}</Text>*/}
            {/*<Text style={styles.body1}>{invoiceTo.phone}</Text>*/}
          </View>
        </View>

        <View key={'v6'} style={[styles.gridContainer, styles.mb40]}>
          <View key={'v7'} style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Date of Issue</Text>
            <Text style={styles.body1}>{fDate(dateOfIssue)}</Text>
          </View>
          <View key={'v8'} style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Due date</Text>
            <Text style={styles.body1}>{fDate(dueDate)}</Text>
          </View>
        </View>

        <View key={'v9'} style={[styles.mb40]}>
          <Text style={[styles.overline, styles.mb8]}>Payment Instructions</Text>
          <View
            style={{
              width: '60%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 12,
            }}
          >
            <View style={[styles.mr16]}>
              <Text style={styles.subtitle2}>Network</Text>
              <Text style={styles.body1}>{networkObj?.title}</Text>
            </View>
            <View style={styles.mr16}>
              <Text style={styles.subtitle2}>Cryptocurrency</Text>
              <Text style={styles.body1}>{cryptocurrency}</Text>
            </View>
            <View style={styles.mr16}>
              <Text style={[styles.subtitle2]}>Reference Fiat Currency</Text>
              <Text style={styles.body1}>{fiat}</Text>
            </View>
          </View>
          <View style={{ marginBottom: 12 }}>
            <Text style={styles.subtitle2}>Wallet Address</Text>
            <Text style={styles.body1}>{walletAddress}</Text>
          </View>
          <View style={{ marginBottom: 12 }}>
            <Text style={[styles.subtitle2]}>Amount to pay in Cryptocurrency</Text>
            <Text style={styles.h4}>
              {replaceCurrencySymbol(fCurrency(totalPrice), currencySymbol)}
            </Text>
          </View>
          <View>
            <Text style={{ padding: 15, backgroundColor: '#FFD666' }}>
              Only send {cryptocurrency} to this Wallet Address on {networkObj?.title} network.
              Don't send {cryptocurrency} from other networks or it will result in a loss of funds.
            </Text>
          </View>
        </View>

        <Text style={[styles.overline, styles.mb8]}>Products / Services</Text>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Description</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Qty</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Unit Price</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Total</Text>
              </View>
            </View>
          </View>

          <View key={'v22'} style={styles.tableBody}>
            {items.map((item, index) => (
              <View style={styles.tableRow} key={item.id}>
                <View key={'v23'} style={styles.tableCell_1}>
                  <Text>{index + 1}</Text>
                </View>

                <View key={'v24'} style={styles.tableCell_2}>
                  <Text style={styles.subtitle2}>{item.title}</Text>
                  <Text>{item.description}</Text>
                </View>

                <View key={'v25'} style={styles.tableCell_3}>
                  <Text>{item.quantity}</Text>
                </View>

                <View key={'v26'} style={styles.tableCell_3}>
                  <Text>{item.price}</Text>
                </View>

                <View key={'v27'} style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>
                    {replaceCurrencySymbol(fCurrency(item.price * item.quantity), currencySymbol)}
                  </Text>
                </View>
              </View>
            ))}

            <View key={'v28'} style={[styles.tableRow, styles.noBorder]}>
              <View key={'v29'} style={styles.tableCell_1} />
              <View key={'v30'} style={styles.tableCell_2} />
              <View key={'v31'} style={styles.tableCell_3} />
              <View key={'v32'} style={styles.tableCell_3}>
                <Text>Subtotal</Text>
              </View>
              <View key={'v33'} style={[styles.tableCell_3, styles.alignRight]}>
                <Text>{replaceCurrencySymbol(fCurrency(subTotalPrice), currencySymbol)}</Text>
              </View>
            </View>

            <View key={'v34'} style={[styles.tableRow, styles.noBorder]}>
              <View key={'v35'} style={styles.tableCell_1} />
              <View key={'v36'} style={styles.tableCell_2} />
              <View key={'v37'} style={styles.tableCell_3} />
              <View key={'v38'} style={styles.tableCell_3}>
                <Text>Discount</Text>
              </View>
              <View key={'v39'} style={[styles.tableCell_3, styles.alignRight]}>
                <Text>{replaceCurrencySymbol(fCurrency(-discount), currencySymbol)}</Text>
              </View>
            </View>

            <View key={'v40'} style={[styles.tableRow, styles.noBorder]}>
              <View key={'v41'} style={styles.tableCell_1} />
              <View key={'v42'} style={styles.tableCell_2} />
              <View key={'v43'} style={styles.tableCell_3} />
              <View key={'v44'} style={styles.tableCell_3}>
                <Text>Taxes</Text>
              </View>
              <View key={'v45'} style={[styles.tableCell_3, styles.alignRight]}>
                <Text>{replaceCurrencySymbol(fCurrency(taxes), currencySymbol)}</Text>
              </View>
            </View>

            <View key={'v46'} style={[styles.tableRow, styles.noBorder]}>
              <View key={'v47'} style={styles.tableCell_1} />
              <View key={'v48'} style={styles.tableCell_2} />
              <View key={'v49'} style={styles.tableCell_3} />
              <View key={'v50'} style={styles.tableCell_3}>
                <Text style={styles.h4}>Total</Text>
              </View>
              <View key={'v51'} style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h4}>
                  {replaceCurrencySymbol(fCurrency(totalPrice), currencySymbol)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/*<View style={[styles.gridContainer, styles.footer]}>*/}
        {/*  <View style={styles.col8}>*/}
        {/*    <Text style={styles.subtitle2}>NOTES</Text>*/}
        {/*    <Text>*/}
        {/*      We appreciate your business. Should you need us to add VAT or extra notes let us know!*/}
        {/*    </Text>*/}
        {/*  </View>*/}
        {/*  <View style={[styles.col4, styles.alignRight]}>*/}
        {/*    <Text style={styles.subtitle2}>Have a Question?</Text>*/}
        {/*    <Text>support@abcapp.com</Text>*/}
        {/*  </View>*/}
        {/*</View>*/}
      </Page>
    </Document>
  );
}
