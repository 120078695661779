import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/checkout';
import kanbanReducer from './slices/kanban';
import coinReducer from './slices/coin';
import networkReducer from './slices/network';
import tokenPriceReducer from './slices/tokentPrice';
import analyticsReducer from './slices/analytics';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  coin: coinReducer,
  network: networkReducer,
  tokenPrice: tokenPriceReducer,
  analytics: analyticsReducer,
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
