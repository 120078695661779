// form
import { useFormContext } from 'react-hook-form';
// @mui
import { Box, MenuItem, Stack, Typography } from '@mui/material';
// components
import { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import Image from '../../../../components/image';
import { NETWORKS, WALLET_NETWORKS } from '../../../../assets/data/networks';

export default function InvoicePaymentDetails() {
  const { control, watch, resetField } = useFormContext();
  const network = watch('network');
  // resetField('cryptocurrency');
  const foundNetwork = NETWORKS.find((n) => n.chainId === network);
  const cryptocurrencies = foundNetwork ? foundNetwork.coins : [];
  const fieldSize = 'small';

  const resetCryptocurrency = () => {
    resetField('cryptocurrency');
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <RHFSelect
          size={fieldSize}
          fullWidth
          name="network"
          label="Network"
          SelectProps={{ native: false }}
        >
          {WALLET_NETWORKS.map((option) => (
            <MenuItem onClick={resetCryptocurrency} key={option.chainId} value={option.chainId}>
              <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                <Image disabledEffect sx={{ width: 22, height: 22, mr: 1 }} src={option.iconUri} />
                <Typography>{option.title}</Typography>
              </Box>
            </MenuItem>
          ))}
        </RHFSelect>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFSelect
            size={fieldSize}
            sx={{ width: { xs: 1, sm: '70%' } }}
            name="cryptocurrency"
            label="Cryptocurrency"
            SelectProps={{ native: false }}
          >
            {cryptocurrencies.map((option) => (
              <MenuItem key={option.value} value={`${option.value} (${option.label})`}>
                <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Image disabledEffect sx={{ width: 22, height: 22, mr: 1 }} src={option.icon} />
                  <Typography>
                    {option.value} ({option.label})
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </RHFSelect>
          <RHFSelect
            size={fieldSize}
            sx={{ width: { xs: 1, sm: '30%' } }}
            name="fiat"
            label="Fiat currency"
          >
            {['USD', 'EUR'].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </RHFSelect>
        </Stack>
        <RHFTextField size={fieldSize} name="walletAddress" label="Wallet Address" />
      </Stack>
    </Box>
  );
}
