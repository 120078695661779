import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
// utils
// @types
//
import { dispatch, store } from '../store';
import { IToken, ITokenPrice, ITokenPriceState, TokenType } from '../../@types/network';
import { NETWORKS } from '../../assets/data/networks';
import axios from '../../utils/axios';
import { toTwoDecimalPlaces } from '../../utils/number';
import { selectNetwork } from './network';

// ----------------------------------------------------------------------

const initialState: ITokenPriceState = {
  isLoading: false,
  error: null,
  prices: {},
};

const slice = createSlice({
  name: 'tokenPrice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getTokenPricesSuccess(state, action: PayloadAction<ITokenPrice>) {
      state.isLoading = false;
      state.prices = { ...state.prices, ...action.payload };
    },
    clearTokenPrices(state) {
      state.isLoading = false;
      state.error = null;
      state.prices = {};
    },
  },
});

// Actions
export const { clearTokenPrices } = slice.actions;

//Selectors
const selectTokenPrice = (state) => state.tokenPrice;

export const selectTokenBalancesByNetworkSelector = createSelector(
  [selectTokenPrice, selectNetwork],
  (tokenPrice, network) =>
    tokenPrice.prices[network.selectedChainId] ? tokenPrice.prices[network.selectedChainId] : []
);

export const selectBalancesByNetworkSelector = createSelector(
  [selectTokenPrice, selectNetwork],
  (tokenPrice, network) =>
    tokenPrice.prices[network.selectedChainId]
      ? tokenPrice.prices[network.selectedChainId].reduce(
          (acc, cur) => {
            if (cur.tokenType === TokenType.stablecoin) {
              acc.stablecoinsBalance += cur.balanceUSD;
            } else {
              acc.othersBalance += cur.balanceUSD;
            }
            return acc;
          },
          { stablecoinsBalance: 0, othersBalance: 0 }
        )
      : { stablecoinsBalance: 0, othersBalance: 0 }
);

// Reducer
export default slice.reducer;

export function getTokenPrices(chainId: number, tokens: IToken[], force = false) {
  return async () => {
    if (tokens.length === 0) return;
    const state = store.getState();
    if (!force && state.tokenPrice.prices[chainId]) return;
    dispatch(slice.actions.startLoading());
    try {
      const chainName = NETWORKS.find((n) => n.chainId === chainId)?.name;
      const paramsPrices = tokens.map((t) => `addresses=${chainName}:${t.tokenAddress}`).join('&');
      const prices = await axios.get(
        `https://api.portals.fi/v2/tokens?${paramsPrices}&networks=${chainName}`
      );
      const mappedPrices = prices.data.tokens.map((t) => {
        const token = tokens.find((iT) => iT.tokenAddress === t.address);
        return {
          tokenType: token?.tokenType,
          tokenAddress: t.address,
          priceUSD: toTwoDecimalPlaces(t.price),
          balanceUSD: token?.balance ? toTwoDecimalPlaces(token.balance * t.price) : null,
        };
      });
      dispatch(slice.actions.getTokenPricesSuccess({ [chainId]: mappedPrices }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}