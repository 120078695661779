import { useNavigate } from 'react-router-dom';
// form
// @mui
import { Box, Card, CardContent, CardHeader, Grid, Link, Stack, Typography } from '@mui/material';
// routes
// @types
import { IProduct } from '../../../@types/product';
// components
import { CustomFile } from '../../../components/upload';
import { useSnackbar } from '../../../components/snackbar';
import Iconify from '../../../components/iconify';
import { alpha, styled } from '@mui/material/styles';
import Image from '../../../components/image';
import { CHAIN_ID, NETWORKS } from '../../../assets/data/networks';
import { forwardRef } from 'react';
import { shortenAddress } from '../../../utils/shortenAddress';
import Logo from '../../../components/logo';
import { fDateTime } from '../../../utils/formatTime';
import Label from '../../../components/label';
import { fCryptocurrency } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<IProduct, 'images'> {
  taxes: boolean;
  inStock: boolean;
  images: (CustomFile | string)[];
}

type Props = {
  current?: any;
};

type TimelineType = {
  key: number;
  title: string;
  des: string;
  time: string;
  color?: 'primary' | 'info' | 'success' | 'warning' | 'error' | 'inherit' | 'grey' | 'secondary';
  icon: React.ReactElement;
};

const NodeChain = styled(Stack)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  alignItems: 'center',
}));

const TransactionLink = forwardRef<HTMLDivElement, any>(({ url, sx, ...other }, ref) => (
    <Link
      href={url}
      sx={{ display: 'flex', alignItems: 'center', ...sx }}
      color={'inherit'}
      target="_blank"
      rel="noopener"
    >
      <Typography variant={'caption'}>Transaction</Typography>{' '}
      <Iconify sx={{ ml: '0.3em' }} icon="eva:external-link-fill" width={16} />
    </Link>
  ));

function LineComponent({ url, height = '50px' }: { url: string; height?: string }) {
  return (
    <Stack sx={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', columnGap: 0.5, m: 1 }}>
      <Box
        sx={{
          gridColumnStart: 2,
          borderLeft: '3px solid',
          height: height,
          borderColor: (theme) => alpha(theme.palette.grey[500], 0.4),
        }}
       />
      <TransactionLink url={url} sx={{ gridColumnStart: 3 }} />
    </Stack>
  );
}

export default function PaymentDetailsForm({ current }: Props) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const buyersNetworkIcon = NETWORKS.find((n) => n.chainId === CHAIN_ID.OPTIMISM)?.iconUri;
  const bridgedNetworkIcon = NETWORKS.find((n) => n.chainId === current?.chainId)?.iconUri;
  const buyersWalletAddress = shortenAddress('0xfcc386b8d91630c08ce73f1476d739c5e28679eb');
  const sellersWalletAddress = shortenAddress('0xe05d93f6af0ea0358584eb7c206d2a712a18eabf');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={7}>
        <Card sx={{ p: 3 }}>
          <Stack spacing={2.5}>
            <Stack
              direction={'row'}
              spacing={2}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant={'button'} noWrap width={'6em'}>
                Ref
              </Typography>
              <Typography variant={'body1'} noWrap>
                {current?.id}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant={'button'} noWrap width={'6em'}>
                Created At
              </Typography>
              <Typography variant={'body1'} noWrap>
                {fDateTime(current?.createdAt)}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant={'button'} noWrap width={'6em'}>
                Status
              </Typography>
              <Typography variant={'body1'} noWrap>
                <Label
                  variant="soft"
                  color={
                    current?.status === 'Pending'
                      ? 'warning'
                      : current?.status === 'Completed'
                      ? 'primary'
                      : 'error'
                  }
                >
                  {current?.status}
                </Label>
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant={'button'} noWrap width={'6em'}>
                Product
              </Typography>
              <Typography variant={'body1'} noWrap>
                {current?.product}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant={'button'} width={'6em'}>
                Received Amount
              </Typography>
              <Typography variant={'body1'} noWrap>
                {fCryptocurrency(current?.amount)} {current?.currency}
              </Typography>
            </Stack>
            <Stack
              direction={'row'}
              spacing={2}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant={'button'} width={'6em'}>
                Network
              </Typography>
              <Typography variant={'body1'} noWrap>
                {current?.network}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Grid>

      <Grid item xs={12} md={5}>
        <Stack spacing={3}>
          <Card>
            <CardHeader title="Transaction Chain" />
            <CardContent>
              {current?.status === 'Completed' ? (
                <>
                  <NodeChain>
                    <Typography variant={'subtitle1'}>Buyer's Wallet</Typography>
                    <Typography variant={'caption'} sx={{ mb: 1 }} color={'text.secondary'}>
                      {buyersWalletAddress}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        display: 'inline-flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Paid by{' '}
                        <Typography variant="subtitle2" component={'span'}>
                          ETH
                        </Typography>
                      </span>
                      <span> on</span>
                      <Typography
                        component={'span'}
                        variant="subtitle2"
                        sx={{
                          display: 'inline-flex',
                          padding: '0 0.4em',
                        }}
                      >
                        <Image
                          sx={{ width: 24, height: 24, mr: '0.2em' }}
                          src={buyersNetworkIcon}
                        />{' '}
                        Optimism
                      </Typography>
                      network
                    </Typography>
                  </NodeChain>
                  <LineComponent
                    url={
                      'https://optimistic.etherscan.io/tx/0xdeecdacbd2e117a0e9b9cb945f70a07340a8147751a2a1ea85e7a88614342539'
                    }
                  />
                  <NodeChain>
                    <Typography variant={'subtitle1'}>Bridge Component</Typography>
                  </NodeChain>
                  <LineComponent
                    url={
                      'https://polygonscan.com/tx/0x51e5af9aa4ccdbb23b54f7f1ca181adcf04bdb652d210ac01482ca1f400355cc'
                    }
                  />
                  <NodeChain>
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        display: 'inline-flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Bridged to{' '}
                        <Typography variant="subtitle2" component={'span'}>
                          WETH
                        </Typography>
                      </span>
                      <span> on</span>
                      <Typography
                        component={'span'}
                        variant="subtitle2"
                        sx={{
                          display: 'inline-flex',
                          padding: '0 0.4em',
                        }}
                      >
                        <Image
                          sx={{ width: 24, height: 24, mr: '0.2em' }}
                          src={bridgedNetworkIcon}
                        />{' '}
                        Polygon
                      </Typography>
                      network
                    </Typography>
                  </NodeChain>
                  <LineComponent
                    url={
                      'https://polygonscan.com/tx/0xc807d53fa28c73d348fa2dadfd7f5a67d8c32a7ee64bffc79c93d024ba1079f7'
                    }
                  />
                  <NodeChain>
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        display: 'inline-flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Swaped to{' '}
                        <Typography variant="subtitle2" component={'span'}>
                          {current?.currency}
                        </Typography>
                      </span>
                      <span> on</span>
                      <Typography
                        component={'span'}
                        variant="subtitle2"
                        sx={{
                          display: 'inline-flex',
                          padding: '0 0.4em',
                        }}
                      >
                        <Image
                          sx={{ width: 24, height: 24, mr: '0.2em' }}
                          src={bridgedNetworkIcon}
                        />{' '}
                        {current?.network}
                      </Typography>
                      network
                    </Typography>
                  </NodeChain>
                  <Stack direction={'row'} justifyContent={'space-between'} spacing={3}>
                    <Stack>
                      <LineComponent
                        height={'120px'}
                        url={
                          'https://polygonscan.com/tx/0x6083c4849792c2a21702e5ca22addd41152efc4ed517fd53c500798138d58339'
                        }
                      />
                    </Stack>
                    <Stack>
                      <LineComponent
                        url={
                          'https://polygonscan.com/tx/0xc460ad02b71637fc36819b9cca0a41a3b20be5c24d04a2b885598753a2fe3d58'
                        }
                      />
                      <NodeChain>
                        <Logo disabledLink sx={{ width: 65, height: 'auto' }} />
                        <Typography variant={'caption'}>Fee</Typography>
                      </NodeChain>
                    </Stack>
                  </Stack>
                  <NodeChain>
                    <Typography variant={'subtitle1'}>Seller's Wallet</Typography>
                    <Typography variant={'caption'} sx={{ mb: 1 }} color={'text.secondary'}>
                      {sellersWalletAddress}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        display: 'inline-flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <span style={{ whiteSpace: 'nowrap' }}>
                        Received{' '}
                        <Typography variant="subtitle2" component={'span'}>
                          {current?.currency}
                        </Typography>
                      </span>
                      <span> on</span>
                      <Typography
                        component={'span'}
                        variant="subtitle2"
                        sx={{
                          display: 'inline-flex',
                          padding: '0 0.4em',
                        }}
                      >
                        <Image
                          sx={{ width: 24, height: 24, mr: '0.2em' }}
                          src={bridgedNetworkIcon}
                        />{' '}
                        {current?.network}
                      </Typography>
                      network
                    </Typography>
                  </NodeChain>
                </>
              ) : (
                <Typography sx={{ textAlign: 'center' }} color={'text.secondary'}>
                  Coming Soon
                </Typography>
              )}
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
}
